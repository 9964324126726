import HomeStyled from "./styles";

const HomePage = () => {
  return (
    <HomeStyled>
      <img src="./img/clay-studio.jpg" alt="" />
    </HomeStyled>
  );
};

export default HomePage;
